'use strict'

###* @ngdoc object
 # @name lpaData
 # @description

###
angular
.module 'lpaData', [

]
